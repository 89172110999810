import axios from 'axios';
import { USE_MOCK } from 'config/app';
import * as R from 'remeda';
import { apiUtility } from 'utility';

import { baseEndpoint } from './config';
import mockedGetAggregatedActivityHoursJson from './mocked/getAggregatedActivityHours.json';
import mockedGetProducedActivitiesJson from './mocked/getProducedActivities.json';
import {
  transformAggregatedEmployeeProducedActivities,
  transformAggregatedProducedActivities,
  transformCalculatedExternals,
  transformCalculatedInternals,
  transformFiles,
  transformProducedActivities,
} from './transformers';
const getProducedActivities = (params: {
  companyId: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { companyId, useMocked = USE_MOCK, bearerToken } = params;
  if (useMocked)
    return Promise.resolve(
      mockedGetProducedActivitiesJson as ReturnType<
        typeof transformProducedActivities
      >,
    );

  return axios
    .get<{
      producedActivities: API.ProducedActivity[];
      errorMessages: API.ErrorMessage[];
      startDate: string;
      endDate: string;
    }>(`${baseEndpoint}/producedactivities/${companyId}`, {
      headers: { Authorization: `Bearer ${bearerToken}` },
    })
    .then((response) => {
      const { data } = response;

      const transformed = transformProducedActivities(
        data.producedActivities,
        data.errorMessages,
      );
      return transformed;
    });
};

const getAggregatedEmployeeHours = (params: {
  companyId: string;
  bearerToken: string;
  useMocked?: boolean;
  dates: Time.Date[];
}) => {
  const { bearerToken, companyId, dates, useMocked = USE_MOCK } = params;

  if (useMocked) {
    // eslint-disable-next-line no-console
    console.warn('Get Aggregated EmployeeHour has no mock');
    return Promise.resolve({
      employeeHourDomain: { allIds: [], byId: {} },
    } as ReturnType<typeof transformAggregatedEmployeeProducedActivities>);
  }

  // Empty dates will result in all history in response,
  // Not ideal.
  if (dates.length === 0)
    return Promise.resolve({
      employeeHourDomain: { allIds: [], byId: {} },
    } as ReturnType<typeof transformAggregatedEmployeeProducedActivities>);

  const _dates: API.DateResolution[] = apiUtility.toDateResolution(dates);

  return axios
    .post<
      API.AggregatedProducedActivity[]
    >(`${baseEndpoint}/aggregatedactivities/${companyId}`, { resolution: 'employee', dates: _dates }, { headers: { Authorization: `Bearer ${bearerToken}` } })
    .then((response) => {
      const { data } = response;
      const transformed = transformAggregatedEmployeeProducedActivities({
        aggregatedProducedActivities: data,
      });

      return transformed;
    });
};
/**
 * We want to get all the history for now.
 * In the future we can ask for history relevant for specific date interval.
 * Logic & Synchronization is much easier if we just have everything.
 */
const getAggregatedActivityHours = (params: {
  companyId: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { bearerToken, companyId, useMocked = USE_MOCK } = params;

  if (useMocked) {
    return Promise.resolve(
      mockedGetAggregatedActivityHoursJson as ReturnType<
        typeof transformAggregatedProducedActivities
      >,
    );
  }

  return axios
    .post<
      API.AggregatedProducedActivity[]
    >(`${baseEndpoint}/aggregatedactivities/${companyId}`, { resolution: 'activity' }, { headers: { Authorization: `Bearer ${bearerToken}` } })
    .then((response) => {
      const { data } = response;
      const transformed = transformAggregatedProducedActivities({
        aggregatedProducedActivities: data,
      });

      return transformed;
    });
};

const getCalculatedExternals = (params: {
  companyId: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { bearerToken, companyId } = params;

  const body = {
    resolution: 'SubZoneId', // ZoneId, SubZoneId
    //"unitOfMeasureId": "952303de-5041-44de-9613-df730ac7bfe1",
    returnRawData: true, // returnRawData = true, aggregated per day
    dates: [], // Empty list === all dates
  };

  return axios
    .post<API.CalculatedExternal[]>(
      `${baseEndpoint}/companies/${companyId}/calculated-externals`,
      body,
      {
        headers: { Authorization: `Bearer ${bearerToken}` },
      },
    )
    .then((response) => {
      const { data } = response;
      const transformed = transformCalculatedExternals({
        calculatedExternals: data,
      });
      return transformed;
    });
};

const getCalculatedInternals = (params: {
  companyId: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { bearerToken, companyId } = params;

  const body = {
    resolution: 'SubZoneId', // ZoneId, SubZoneId
    unitOfMeasureId: '952303DE-5041-44DE-9613-DF730AC7BA9B', // TODO: hardcoded for now because we had rows without subZoneID
    returnRawData: true, // returnRawData = true, aggregated per day
    dates: [], // Empty list === all dates
  };

  return axios
    .post<API.CalculatedInternal[]>(
      `${baseEndpoint}/companies/${companyId}/calculated-internals`,
      body,
      {
        headers: { Authorization: `Bearer ${bearerToken}` },
      },
    )
    .then((response) => {
      const { data } = response;
      const transformed = transformCalculatedInternals({
        calculatedInternals: data,
      });
      return transformed;
    });
};

const getFiles = (params: {
  companyId: string;
  bearerToken: string;
  page: number;
  pageSize: number;
}) => {
  const { companyId, bearerToken, page, pageSize } = params;

  return axios
    .get<API.Response<API.File[]>>(
      `${baseEndpoint}/companies/${companyId}/files`,
      {
        headers: { Authorization: `Bearer ${bearerToken}` },
        params: { pageNumber: page, pageSize },
      },
    )
    .then((response) => {
      const {
        data: { data, error, hasSucceeded },
        headers,
      } = response;

      const { CurrentPage, PageSize, TotalPages, TotalCount } = JSON.parse(
        headers['x-pagination'],
      ) as API.Pagination;

      const pagination: System.Pagination = {
        page: CurrentPage,
        totalPages: TotalPages,
      };

      const transformed = transformFiles(data);

      return { fileDomain: transformed, pagination };
    });
};

const uploadFile = (params: {
  companyId: string;
  bearerToken: string;
  file: File;
}) => {
  const { companyId, bearerToken, file } = params;

  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`${baseEndpoint}/companies/${companyId}/upload`, formData, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export {
  getAggregatedActivityHours,
  getAggregatedEmployeeHours,
  getCalculatedExternals,
  getCalculatedInternals,
  getFiles,
  getProducedActivities,
  uploadFile,
};
