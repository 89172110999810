import { useTheme } from '@mui/material';
import { Card, Table } from 'components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { loadingSelectors } from 'store/glue/loading';
import { integrationsSelectors } from 'store/page/integrations';
import { ScrollableView } from 'templates';
import Types from 'Types';

import FileRow from './FileRow';
import ViewActions from './ViewActions';

const headers: (LabelItem<keyof Data.File> & { columns?: number })[] = [
  { id: 'importedAt', name: 'imported', columns: 2 },
  { id: 'name', name: 'file', columns: 4 },
  { id: 'proccessed', name: 'processed', columns: 2 },
  { id: 'isProcessed', name: 'status', columns: 2 },
];

const mapStateToProps = (state: Types.RootState) => ({
  rows: integrationsSelectors.displayRows(state),
  isLoading: loadingSelectors.isLoading(state, {
    types: [
      'page/integrations/START_FETCH_PAGE',
      'page/integrations/START_UPLOAD_FILE',
    ],
  }),
});
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const FilesView: FunctionComponent<Props> = (props) => {
  const { rows, isLoading } = props;
  const theme = useTheme();
  return (
    <ScrollableView direction="column" isLoading={isLoading}>
      <Card
        title="file uploads"
        sx={(theme) => ({ width: theme.sizes[1597] })}
        action={<ViewActions />}
      >
        <Table
          rows={rows}
          headers={headers}
          renderRow={(row, alternatingBackground, headers) => (
            <FileRow
              key={row.id}
              row={row}
              alternatingBackground={alternatingBackground}
              headers={headers}
              theme={theme}
            />
          )}
        ></Table>
      </Card>
    </ScrollableView>
  );
};

export default connect(mapStateToProps)(FilesView);
