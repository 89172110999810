import { DOMAIN_TYPE } from '../constants';

const DOMAIN_NAME = 'integrations';

export const SET_PAGINATION =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/SET_PAGINATION` as const;

export const START_UPLOAD_FILE =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_UPLOAD_FILE` as const;

export const START_FETCH_PAGE =
  `${DOMAIN_TYPE}/${DOMAIN_NAME}/START_FETCH_PAGE` as const;
