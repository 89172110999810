import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { fileSelectors } from 'store/data/file';
import {
  integrationsActions,
  integrationsSelectors,
} from 'store/page/integrations';
import { TabsPage } from 'templates';
import Types from 'Types';

import FilesView from './FilesView';
import PageActions from './PageActions';

const mapStateToProps = (state: Types.RootState) => ({
  pagination: integrationsSelectors.pagination(state),
  files: fileSelectors.list(state),
});
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  fetchPage: () => dispatch(integrationsActions.startFetchPage()),
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps;

let didInit = false;
const IntegrationsPage: FunctionComponent<Props> = (props) => {
  const { pagination, fetchPage } = props;

  useEffect(() => {
    if (didInit) return;
    if (R.isDefined(pagination)) return;
    didInit = true;
    fetchPage();
  }, [pagination, fetchPage]);

  return (
    <TabsPage
      tabs={[{ id: 'files', name: 'files', component: <FilesView /> }]}
      pageActions={<PageActions />}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsPage);
