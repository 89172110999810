import { Stack, Theme, Typography } from '@mui/material';
import { StatusText, TableCell } from 'components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { fileSelectors } from 'store/data/file';
import Types from 'Types';
import { timeUtility } from 'utility';

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => ({
  file: fileSelectors.item(state, { id: ownProps.row.id }),
});
type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  row: PivotTable.Row;
  alternatingBackground: boolean;
  headers?: (LabelItem<string> & { columns?: number })[];
  theme: Theme;
};

type Props = OwnProps & StateProps;

const FileRow: FunctionComponent<Props> = (props) => {
  const { headers, theme, alternatingBackground, file } = props;

  if (!R.isDefined(headers)) return null;
  if (!R.isDefined(file)) return null;

  return (
    <>
      {headers.map((header, index) => (
        <TableCell
          key={header.id}
          theme={theme}
          lastInLine={index === headers.length - 1}
          alternatingBackground={alternatingBackground}
          columns={header.columns}
        >
          <Cell
            header={header as LabelItem<keyof Data.File>}
            file={file}
            theme={theme}
          />
        </TableCell>
      ))}
    </>
  );
};

const Cell: FunctionComponent<{
  header: LabelItem<keyof Data.File>;
  file: Data.File;
  theme: Theme;
}> = (props) => {
  const { header, file, theme } = props;
  switch (header.id) {
    case 'importedAt':
      return (
        <Typography variant="text2" color={theme.palette.text.secondary}>
          {timeUtility.readableTime(new Date(file.importedAt))}
        </Typography>
      );
    case 'name':
      return (
        <Typography variant="text2" color="text.secondary">
          {file.name}
        </Typography>
      );

    case 'proccessed':
      return (
        <Stack direction="row" spacing={theme.distances.tiny}>
          <Typography
            variant="text2"
            color={theme.palette.success.dark}
          >{`${file.proccessed + file.skipped || '-'}`}</Typography>
          {file.skipped !== 0 && (
            <Typography
              variant="text2"
              color={theme.palette.warning.dark}
            >{`(${file.skipped})`}</Typography>
          )}
          {file.errors !== 0 && (
            <Typography
              variant="text2"
              color={theme.palette.error.dark}
            >{`(${file.errors})`}</Typography>
          )}
          <Typography variant="text2" color={theme.palette.text.secondary}>
            /
          </Typography>
          <Typography
            variant="text2"
            color={theme.palette.text.secondary}
          >{`${file.total || '-'}`}</Typography>
        </Stack>
      );

    case 'errors':
      return file.errors === 0 ? (
        <Typography variant="text2" color={theme.palette.text.secondary}>
          -
        </Typography>
      ) : (
        <StatusText status="error" text={file.errors.toString()} />
      );

    case 'isProcessed':
      let text = 'processing';
      let status: 'success' | 'warning' | 'error' = 'warning';

      // done
      if (file.total === file.skipped + file.proccessed + file.errors)
        text = 'done';

      // has errors
      if (file.errors !== 0) {
        status = 'error';
        text = 'error';
      }
      // succeeded
      else if (file.total === file.skipped + file.proccessed)
        status = 'success';

      return <StatusText status={status} text={text} />;
    default:
      return null;
  }
};

export default connect(mapStateToProps)(FileRow);
