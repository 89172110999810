import * as R from 'remeda';
import { ActionType } from 'typesafe-actions';

import * as integrationsActions from './actions';
import * as integrationsConstants from './constants';
import integrationsReducer from './reducer';
import * as integrationsSagas from './sagas';
import * as integrationsSelectors from './selectors';

export type IntegrationsState = {
  pagination: System.Pagination | null;
};
export type IntegrationsAction = ActionType<typeof integrationsActions>;

const integrationsSagaActions = R.pick(integrationsActions, [
  'startUploadFile',
  'startFetchPage',
]);

export type IntegrationsSagaAction = ActionType<typeof integrationsSagaActions>;
export {
  integrationsActions,
  integrationsConstants,
  integrationsReducer,
  integrationsSagas,
  integrationsSelectors,
};
