import { actualAPI } from 'api';
import {
  all,
  call,
  delay,
  put,
  SagaReturnType,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as R from 'remeda';
import { fileActions } from 'store/data/file';
import { userSelectors } from 'store/glue/user';
import { sagaWrapper } from 'store/helpers';
import { ActionType } from 'typesafe-actions';

import {
  integrationsActions,
  integrationsConstants,
  integrationsSelectors,
} from './';

function* startFetchPageSaga(
  action: ActionType<typeof integrationsActions.startFetchPage>,
) {
  function* saga() {
    const bearerToken: ReturnType<typeof userSelectors.bearerToken> =
      yield select(userSelectors.bearerToken);
    if (!R.isDefined(bearerToken)) return;

    const companyId: ReturnType<typeof userSelectors.companyId> = yield select(
      userSelectors.companyId,
    );

    let currentPagination: ReturnType<typeof integrationsSelectors.pagination> =
      yield select(integrationsSelectors.pagination);

    if (!R.isDefined(currentPagination)) currentPagination = { page: 1 };

    const {
      fileDomain,
      pagination,
    }: SagaReturnType<typeof actualAPI.getFiles> = yield call(
      actualAPI.getFiles,
      { companyId, bearerToken, page: currentPagination.page, pageSize: 10 },
    );

    yield put(fileActions.setDomain(fileDomain));
    yield put(integrationsActions.setPagination(pagination));
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

function* startUploadFileSaga(
  action: ActionType<typeof integrationsActions.startUploadFile>,
) {
  function* saga() {
    const bearerToken: ReturnType<typeof userSelectors.bearerToken> =
      yield select(userSelectors.bearerToken);
    if (!R.isDefined(bearerToken)) return;

    const companyId: ReturnType<typeof userSelectors.companyId> = yield select(
      userSelectors.companyId,
    );

    const file = action.payload;

    if (!R.isDefined(file)) return;

    yield call(actualAPI.uploadFile, { companyId, bearerToken, file });
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

export function* watcher() {
  yield all([
    takeLeading(integrationsConstants.START_UPLOAD_FILE, startUploadFileSaga),
    takeLatest(integrationsConstants.START_FETCH_PAGE, startFetchPageSaga),
  ]);
}
