import { Pagination } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { internalActions } from 'store/aggregated/internal';
import {
  integrationsActions,
  integrationsSelectors,
} from 'store/page/integrations';
// import { userSelectors } from 'store/glue/user';
import Types from 'Types';
import { useDebounce } from 'usehooks-ts';

const mapStateToProps = (state: Types.RootState) => ({
  pagination: integrationsSelectors.pagination(state),
  // companyId: userSelectors.companyId(state),
});
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  setPagination: (pagination: System.Pagination) =>
    dispatch(integrationsActions.setPagination(pagination)),

  startFetchPage: () => dispatch(integrationsActions.startFetchPage()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps;
const ViewActions: FunctionComponent<Props> = (props) => {
  const { pagination, setPagination, startFetchPage } = props;

  if (!R.isDefined(pagination)) return null;
  if (!R.isDefined(pagination.totalPages)) return null;

  function onChangePage(event: React.ChangeEvent<unknown>, page: number) {
    setPagination({ ...pagination, page });
    startFetchPage();
  }
  return (
    <>
      <Pagination
        count={pagination.totalPages}
        page={pagination.page}
        size="medium"
        onChange={onChangePage}
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewActions);
