import * as R from 'remeda';
import {
  IntegrationsAction,
  integrationsActions as actions,
  IntegrationsState,
} from 'store/page/integrations';

const initialState: IntegrationsState = { pagination: null };

const integrationsReducer = (
  state: IntegrationsState = initialState,
  action: IntegrationsAction,
): IntegrationsState => {
  switch (action.type) {
    case 'page/integrations/SET_PAGINATION':
      return R.set(state, 'pagination', action.payload);
    default:
      return state;
  }
};

export default integrationsReducer;
