import { FileUpload } from '@mui/icons-material';
import { Button, styled, Typography } from '@mui/material';
import React, { Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'remeda';
import { userSelectors } from 'store/glue/user';
import { integrationsActions } from 'store/page/integrations';
import Types from 'Types';
const mapStateToProps = (state: Types.RootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Types.Dispatch) => ({
  startUpload: (file: File | null) =>
    dispatch(integrationsActions.startUploadFile(file)),
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps;
const PageActions: FunctionComponent<Props> = (props) => {
  const { startUpload } = props;

  function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (!R.isDefined(files)) return;
    const file = files.item(0);
    if (!R.isDefined(file)) return;

    startUpload(file);
  }

  return (
    <>
      <Button
        component="label"
        role={undefined}
        variant="text"
        onClick={() => console.log('hej')}
        sx={(theme) => ({
          marginLeft: theme.distances.medium,
          color: theme.palette.text.secondary,
        })}
        endIcon={<FileUpload />}
        disableRipple
        color="inherit"
      >
        <Typography variant="action1">Upload</Typography>
        <VisuallyHiddenInput type="file" onChange={handleFileSelect} />
      </Button>
    </>
  );
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default connect(mapStateToProps, mapDispatchToProps)(PageActions);
