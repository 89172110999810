import * as R from 'remeda';
import { createSelector } from 'reselect';
import { fileSelectors } from 'store/data/file';
import Types from 'Types';

const domain = (state: Types.RootState) => state.page.integrations;
const pagination = createSelector(domain, (domain) => domain.pagination);

const displayRows = createSelector(fileSelectors.list, (files) => {
  const rows: PivotTable.Row[] = R.pipe(
    files,
    R.sortBy([R.prop('importedAt'), 'desc']),
    R.map((file) => {
      const row: PivotTable.Row = {
        id: file.id,
        indentLevel: 1,
        name: file.name,
        ancestorIds: [],
        childRows: [],
      };
      return row;
    }),
  );
  return rows;
});

export { displayRows, pagination };
