import { createAction } from 'typesafe-actions';

import {
  SET_PAGINATION,
  START_FETCH_PAGE,
  START_UPLOAD_FILE,
} from './constants';

export const setPagination = createAction(
  SET_PAGINATION,
  (payload: System.Pagination | null) => payload,
)();

export const startUploadFile = createAction(
  START_UPLOAD_FILE,
  (payload: File | null) => payload,
)();

export const startFetchPage = createAction(START_FETCH_PAGE)();
