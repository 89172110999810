import { combineReducers } from 'redux';

import { dashboardReducer } from './dashboard';
import { flexHoursReducer } from './flexHours';
import { forecastReducer } from './forecast';
import { integrationsReducer } from './integrations';
import { zoneManagementReducer } from './zoneManagement';

const pageReducers = combineReducers({
  flexHours: flexHoursReducer,
  forecast: forecastReducer,
  dashboard: dashboardReducer,
  zoneManagement: zoneManagementReducer,
  integrations: integrationsReducer,
});

export default pageReducers;
