import { misc } from 'variables';

import {
  DisplayLocation,
  monthConverter,
  shortMonthConverter,
  StrippedDate,
  weekdayConverter,
} from './constants';

function stringify(dateNumber: number, pad = false) {
  const dateString = dateNumber.toString();

  if (pad) return dateString.padStart(2, '0');

  return dateString;
}

function formatYear(date: StrippedDate<Time.Year>, location: DisplayLocation) {
  const { year } = date;

  switch (location) {
    case 'calendar':
    case 'selected-list':
    case 'flexhour-pivot-table-cell-header':
    case 'stepper':
    case 'x-axis':
    case 'x-axis-detailed':
      return stringify(year);

    default:
      return misc.MISSING;
  }
}

function formatMonth(
  date: StrippedDate<Time.Month>,
  location: DisplayLocation,
) {
  const { year, month } = date;
  switch (location) {
    case 'calendar':
      return monthConverter[month] ?? misc.MISSING;

    case 'x-axis':
      return shortMonthConverter[month] ?? misc.MISSING;
    case 'selected-list':
    case 'flexhour-pivot-table-cell-header':
      return `${stringify(year)}, ${monthConverter[month] ?? misc.MISSING}`;
    case 'x-axis-detailed':
      return `${stringify(year)}, ${
        shortMonthConverter[month] ?? misc.MISSING
      }`;
    case 'stepper':
      return `${monthConverter[month] ?? misc.MISSING}, ${stringify(year)}`;
    default:
      return misc.MISSING;
  }
}

function formatWeek(date: StrippedDate<Time.Week>, location: DisplayLocation) {
  const { week, year } = date;

  switch (location) {
    case 'calendar':
    case 'x-axis':
      return stringify(week);
    case 'selected-list':
    case 'flexhour-pivot-table-cell-header':
    case 'x-axis-detailed':
      return `${stringify(year)}, w.${week}`;
    case 'x-axis':
      return `w.${stringify(week)}`;
    case 'dashboard-pivot-table-cell-header':
    case 'dashboard-selected-week':
    case 'forecast-selected-week':
    case 'stepper':
      return `Week ${stringify(week)}, ${stringify(year)}`;
    case 'x-axis-productivity-modal':
      return `Week ${stringify(week)}`;
    default:
      return misc.MISSING;
  }
}

function formatDay(date: StrippedDate<Time.Day>, location: DisplayLocation) {
  const { year, dayOfMonth, month, dayOfWeek } = date;
  switch (location) {
    case 'calendar':
      return stringify(dayOfMonth);
    case 'x-axis':
    case 'x-axis-detailed':
    case 'selected-list':
    case 'forecast-table-day':
    case 'flexhour-pivot-table-cell-header':
    case 'stepper':
    case 'integrations':
      return `${stringify(year)}-${stringify(month + 1, true)}-${stringify(
        dayOfMonth,
        true,
      )}`;

    case 'dashboard-pivot-table-cell-header':
      return `${weekdayConverter[dayOfWeek]}, ${dayOfMonth} ${monthConverter[month]}`;
    default:
      return misc.MISSING;
  }
}

export { formatDay, formatMonth, formatWeek, formatYear, stringify };
