import IntegrationsPage from 'pages/Integrations';
('pages/Integrations');
import ForecastPage from 'pages/Forecast';
import ImprovementsPage from 'pages/Improvements';
import LoginPage from 'pages/Login';
import ProductivityPage from 'pages/Produtivity';
import SchedulePage from 'pages/Schedule';
import SigninOidcPage from 'pages/SigninOidc';
import TimePage from 'pages/Time';
import ZoneManagementPage from 'pages/ZoneManagement';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { NavigationRoute } from 'templates';

import AuthLayout from './AuthLayout';
import { routes } from './constants';
const router = createBrowserRouter([
  {
    path: routes.base,
    element: <AuthLayout />,
    children: [
      { path: routes.signinOidc, element: <SigninOidcPage /> },
      { path: routes.login, element: <LoginPage /> },

      {
        element: <NavigationRoute />,
        children: [
          { path: routes.time, element: <TimePage /> },
          { path: routes.forecast, element: <ForecastPage /> },
          { path: routes.productivity, element: <ProductivityPage /> },
          { path: routes.schedule, element: <SchedulePage /> },
          { path: routes.zoneManagement, element: <ZoneManagementPage /> },
          { path: routes.improvements, element: <ImprovementsPage /> },
          { path: routes.integrations, element: <IntegrationsPage /> },
        ],
      },
    ],
  },
]);

export { router };
